.new{
    margin-bottom: 20px;
}

.new__title{
    font-weight: 400;
    font-size: 16px;
    text-align: left;
    line-height: 1.3;
    color: #fff;
    margin: 15px 0 20px;
    font-family: "Roboto", sans-serif;
}

.new__description{
    font-size: 14px;
    line-height: 24px;
    color: #adadad;
    font-family: "Roboto", sans-serif;
}

@media screen and (min-width: 850px) {
    .new{
        padding-left: 20px;
    }

    .new__title{
        margin-top: 20px;
        font-size: 14px;
    }
}
