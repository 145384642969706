* {
    box-sizing: border-box;
}

a {
    text-decoration: none;
}

body{
    background: #000000c2;
    font-family: "Roboto", sans-serif;
}

.main{
    background-color: #000;
    max-width: 720px;
    width: 100%;
    margin: 0 auto;
    padding: 10px 20px 30px;
}

.main__top{
    max-width: 1100px;
    width: 100%;
    margin: 0 auto;
    background-color: #000000c2;
    padding-bottom: 20px;
    padding-top: 35px;
}

.desktop__info{
    display: none;
}

.logo {
    width: 70px;
    height: 70px;
}

.main__wrapper{
    display: flex;
    gap: 20px;
}

.main__tags{
    display: flex;
    gap: 15px;
    margin-top: 5px;
}

.main__title{
    font-size: 22px;
    color: #ffffff;
    line-height:24px;
    text-align: left;
    word-break: break-word;
}

.main__tag{
    white-space: nowrap;
    font-size: 13px;
    color: #33691e;
    font-weight: 700;
}

.logo{
    border-radius: 20px;
}

.app__info{
    margin-top: 20px;
    overflow-x: auto;
    overflow-y: hidden;
}

.app__list{
    min-width: 320px;
    width: 100%;
    display: flex;
    justify-content: center;
}

.app__item{
    width: 110px;
    height: 45px;
    display: flex;
    justify-content: center;

    border-right: 1px solid #ddd;
}

.app__item:first-of-type{
    padding-right: 30px;
}

.app__item:last-of-type {
    border-right: transparent;
}

.app__content{
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
}

.app__description{
    font-size: 12px;
    color: #898989;
    margin-top: 5px;
}

.app__title{
    font-weight: 500;
    font-size: 14px;
    color: #fff
}

.main__button{
    margin-top: 10px;
    background-color: rgb(0, 135, 95);
    cursor: pointer;
    height: 35px;
    min-width: 100%;
    max-width: 320px;
    font-size: 14px;
    font-family: "Roboto";
    font-weight: 500;
    color: #fff;
    border: transparent;
    border-bottom: 2px solid transparent;
    border-radius: 4px;
    outline: none;
}

.main__images{
    border-radius: 20px;
}

.main__thumb{
    display: flex;
    overflow: auto;
    height: 215px;
    margin-top: 30px;
    gap: 8px;
}

.main__about{
    color: #ffffff;
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 20px;
    margin-top: 10px;
}

.main__list{
    list-style: disc;
    padding-left: 15px;
}

.main__list--blur{
    opacity: 0.5;
    height: 20px;
    overflow: hidden;
}

.main__item--one{
    font-size: 14px;
    line-height: 24px;
    color: #adadad;
}

.main__item{
    font-size: 14px;
    line-height: 24px;
    color: #adadad;

}

.show__button{
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    border: none;
    color: green;
    height: 35px;
    font-size: 14px;
    font-family: "Roboto";
    font-weight: 500;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    max-width: 320px;
    width: 100%;
    margin: 10px auto 0;
}

.line{
    height: 1px;
    background-color: #eaeaea;
    width: 100%;
    margin: 0 auto
}

.app__wrapper{
    display: flex;
}

.greyLine{
    width: 345px;
    height: 3px;
    border-radius: 10px;
    background-color: #eaeaea;
}

@keyframes downloading {
    0%{
        width: 0%;
    }

    100%{
        width: 100%;
    }
}

.pwa__link{
    display: flex;
    justify-content: center;
    align-items: center;
}

.greenLine{
    width: 0%;
    height: 3px;
    border-radius: 10px;
    background-color: rgb(0, 135, 95);
    animation: downloading 15.4s ease-in-out forwards;
}

.lines__wrapper{
    margin-top: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.megabytes{
    color: #fff;
}

.years{
    display: none;
}

.greyStars__wrapper{
    display: flex;
    gap: 5px;
}

.top__wrapper{
    display: flex;
    align-items: center;
    gap: 25px;
    margin-bottom: 8px;
}

.main__wrap{
    margin-right: 50px;
}

.bottom__wrapper{
    display: flex;
    align-items: center;
    gap: 5px;
}

.bottom__text{
    color: #616161;
    font-size: 13px;
}

.top__text{
    color: #8d8d8d;
    font-weight: 700;
    font-size: 13px;
}

@media screen and (max-width: 430px) {
    .main__top {
        padding: 0;
    }
}

@media screen and (max-width: 390px) {
    .greyLine{
        width: 310px;
    }
}

@media screen and (max-width: 370px) {
    .main__title {
        font-size: 18px;
    }
}

@media screen and (max-width: 335px) {
    .main__title {
        font-size: 16.5px;
    }
}

@media screen and (max-width: 350px) {
    .greyLine {
        width: 280px;
    }
}

@media screen and (min-width: 850px) {
.main__thumb{
    padding-left: 20px;
}

    .desktop__info{
        display: block;
        margin-left: auto;
        padding-right: 30px;
    }
    .main{
        position: relative;
        padding: 20px 5px 30px;
    width: 720px;
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, .14),
            0 3px 3px -2px rgba(0, 0, 0, .12),
            0 1px 8px 0 rgba(0, 0, 0, .2);
    }   

    .logo{
        width: 180px;
        height: 180px;
        border-radius: 0;
    }

    .main__title{
        font-size: 26px;
        color: #ffffff;
        line-height: 35px;
    }

    .app__info{
        display: none;
    }

    .main__about{
        display: none;
    }

    .main__wrapper{
        padding-left: 40px;
    }

    .years{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
        width: 15px;
        height: 13px;
        background-color: #fff;
        border: 0.5px solid rgba(0, 0, 0, .685);
        font-family: "Roboto", sans-serif;
        color: rgba(0, 0, 0, .685);
        font-size: 8px;
        font-weight: 500;
        line-height: 1;
    }

    .main__button{
        position: absolute;
        top: 100px;
        right: 30px;
        width: 100%;
        max-width: 240px;
        min-width: auto;
    }

    .main__item--one{
        padding-left: 20px;
    }

    .main__list{
        padding-left: 35px;
    }
}