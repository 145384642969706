.additional__wrapper{
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.additional{
    margin-top: 20px;
}

.additional__wrapper--desktop{
    display: none;
}

.additional__title{
    line-height: 25px;
    font-weight: 400;
    font-size: 16px;
    color: #fff;
    margin-bottom: 20px;
}

.additional__text{
    color: #fff;
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
}

.additional__description{
    font-weight: 300;
    font-size: 14px;
    line-height: 25px;
    color: #adadad;
}

.additional__thumb{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.additional__thumb:last-of-type{
    padding-right: 50px;
}

@media screen and (min-width: 850px) {
    .additional__wrapper--desktop {
            display: flex;
    }

    .additional__wrapper--mobile{
        display: none;
    }
    .additional{
        margin-top: 30px;
        padding-left: 20px;
    }
    .additional__title{
        font-size: 14px;
    }

    .additional__wrapper{
        gap: 20px;
        flex-direction: column;
        padding-right: 30px;
    }

    .additional__thumb{
        flex-direction: row;
        justify-content: space-between;
    }

    .additional__item{
        width: 100px;
    }
    .additional__thumb:last-of-type{
        padding-right: 0;
    }
}